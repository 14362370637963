// eslint-disable-next-line import/no-anonymous-default-export
export default {
  episodes: {
    tuneIn: {
      heading: 'TUNE IN TO WATCH',
      introAtk: "Find America's Test Kitchen in Your Local PBS TV listing.",
      introCco: "Find Cook's Country in Your Local PBS TV listing.",
      platformsHeading: 'Find us on these platforms',
    },
    quickView: {
      heading: 'WAYS TO WATCH',
      introPre: 'Find',
      introBold: " America's Test Kitchen ",
      introPost: 'in your local PBS TV Listing.',
    },
  },
  meta: {
    title: "America's Test Kitchen",
    home: {
      podcasts: {
        description:
          "Listen to podcasts from America's Test Kitchen, including Proof, an award-winning look at weird, surprising, and funny backstories behind food.",
        title: "Listen to Proof & Other Food Podcasts | America's Test Kitchen",
      },
      shows: {
        description:
          'Watch episodes and videos from our award-winning TV shows, learn from our expert test cooks, and listen to the best new podcasts on food and cooking.',
        title: "Play Episodes, Videos, and Podcasts | America's Test Kitchen",
      },
      skills: {
        description:
          'Watch our kitchen experts demonstrate essential and advanced skills every home cook can master.',
        title:
          "Watch How-to Videos and Learn New Skills | America's Test Kitchen",
      },
    },
    shows: {
      episodes: {
        atk: {
          description:
            'Watch Bridget Lancaster, Julia Collin Davison, and more of our expert Test Kitchen cast members explain why our recipes work and how to choose the best tools and ingredients.',
          title:
            "Watch Episodes and Clips of Our TV Show | America's Test Kitchen",
        },
        cco: {
          description:
            "Watch our expert Cook's Country TV test cooks explain why our recipes work. Cook's Country has the perfect recipes for chicken, slow cooker meals, birthday cake, quick weeknight dinners, desserts for your next holiday dinner & more.",
          title:
            "Watch Episodes and Clips of Cook's Country TV Online | Cook's Country",
        },
      },
      podcasts: {
        proof: {
          description:
            "We tell the weird and surprising and funny backstories around food and drink. Proof goes beyond recipes and cooking to ask the big questions and uncover the hidden backstories that feed your food-obsessed brain. Hosted by Bridget Lancaster. A production of America's Test Kitchen.",
          title: "Proof Podcast | America's Test Kitchen",
        },
        'the-walk-in': {
          description:
            'In The Walk-In podcast, chef Elle Simone Scott explores the unheard stories of the food world’s difference makers. Unfiltered and unexpected, The Walk-In has the conversations you won’t hear anywhere else. A production of America’s Test Kitchen.',
          title: "The Walk-In Podcast | America's Test Kitchen",
        },
        'mystery-recipe': {
          description:
            "Mystery Recipe is a podcast that helps kids AND their grown-ups uncover the fun, fantastical, and fascinating sides of food. Each ingredient-themed episode builds to a grand finale: a mystery recipe cook-along. Hosted by Molly Birnbaum. A production of America's Test Kitchen Kids.",
          title: "Mystery Recipe Podcast | America's Test Kitchen Kids",
        },
      },
      videos: {
        description:
          'Our magazine editors and test cooks explain exactly how and why our recipes work. We are the authority on food and cooking, with science to back it all up.',
        title: "Recipe Videos | Cook's Illustrated",
      },
      youtubeShows: {
        'perfectly-seasonal': {
          description:
            'Our test cooks show you how to make their most loved fall and winter Test Kitchen recipes in their home kitchens.',
          title: "Perfectly Seasonal | America's Test Kitchen",
        },
        'whats-eating-dan': {
          description:
            'Cook’s Illustrated Editor-in-Chief Dan Souza looks at the science behind some of your favorite foods.',
          title: "What's Eating Dan | Cook's Illustrated",
        },
        'gear-heads': {
          description: 'How does it all work? We find out.',
          title: "Gear Heads | America's Test Kitchen",
        },
      },
    },
  },
};
